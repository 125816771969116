<template>
  <b-nav-item-dropdown
      id="dropdown-grouped"
      class="dropdown-language"
      right
      variant="link"
  >
    <template #button-content>
      <b-img
          :alt="currentLocale.locale"
          :src="require(`@/assets/images/flags/${currentLocale.img}`)"
          height="14px"
          width="22px"
      />
      <span class="ml-50 text-body">{{ currentLocale.name }}</span>
    </template>
    <b-dropdown-item
        v-for="localeObj in locales"
        :key="localeObj.locale"
        @click="switchLang(localeObj.locale)"
    >
      <b-img
          :alt="localeObj.locale"
          :src="require(`@/assets/images/flags/${localeObj.img}`)"
          height="14px"
          width="22px"
      />
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {BDropdownItem, BImg, BNavItemDropdown} from 'bootstrap-vue'
import getBrand from "@/config/branding-config";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    locales() {
      return [
        {
          locale: 'en',
          img: 'en.png',
          name: this.$i18n.t('lang.en'),
        },
        {
          locale: 'de',
          img: 'de.png',
          name: this.$i18n.t('lang.de'),
        },
        {
          locale: 'pl',
          img: 'pl.png',
          name: this.$i18n.t('lang.pl'),
        },
      ]
    },
    currentLocale() {
      return this.locales.find(l => l.locale === this.lang())
    },
  },
  methods: {
    switchLang(lang) {
      this.$store.dispatch('lang/switch', {lang})
      const brand = getBrand()
      document.title = `${brand.appName} | ${this.$i18n.t(this.$route.meta.pageTitle)}`
    },
  },
}
</script>

<style>

</style>
