export default [
    {
        title: 'fotostudio.nav.fotostudio',
        icon: 'ImageIcon',
        children: [
            {
                title: 'fotostudio.nav.articles',
                route: 'fotostudio-articles',
                resource: 'photo-studio-s4s-staff',
                action: 'read',
            },
            {
                title: 'fotostudio.nav.update',
                route: 'fotostudio-update-options',
                resource: 'photo-studio-s4s-staff',
                action: 'read',
            },
            {
                title: 'fotostudio.nav.brand-description',
                route: 'fotostudio-brand-description',
                resource: 'photo-studio-s4s-staff',
                action: 'update',
            },
        ],
    },
    {
        title: 'fotostudio.nav.search',
        icon: 'SearchIcon',
        route: 'fotostudio-search',
        resource: 'photo-studio-photographer',
        action: 'read',
    }
]
