<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      developed by
      <b-link
          class="ml-25"
          href="https://www.enbit.de/"
          target="_blank"
      >enbit gmbh</b-link>
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
