<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
            class="nav-link"
            @click="toggleVerticalMenuActive"
        >
          <feather-icon
              icon="MenuIcon"
              size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
<!--      <dark-Toggler class="d-none d-lg-block" />-->
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-button
          v-if="getAccountId"
          variant="outline-secondary"
          class="mr-1"
          @click="returnBackToAdminAccount"
      >
        <feather-icon
            icon="ArrowLeftIcon"
            size="13"
        />
        <span class="ml-25 align-middle">{{ $i18n.t('return_account') }}</span>
      </b-button>
      <locale />
      <b-nav-item-dropdown
          right
          toggle-class="d-flex align-items-center dropdown-user-link"
          class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ auth().name }}
            </p>
            <span class="user-status">
              {{ getSalesPermissionName() }}
            </span>
          </div>
          <b-avatar
              size="40"
              variant="light-primary"
              badge
              :src="require('@/assets/images/avatars/default.png')"
              class="badge-minimal"
              badge-variant="success"
          />
        </template>
        <b-dropdown-item
link-class="d-flex align-items-center"
to="/profile"
>
          <feather-icon
              size="16"
              icon="UserIcon"
              class="mr-50"
          />
          <span>{{ $i18n.t('Profile') }}</span>
        </b-dropdown-item>
        <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="logout"
        >
          <feather-icon
              size="16"
              icon="LogOutIcon"
              class="mr-50"
          />
          <span>{{ $i18n.t('Logout') }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {BAvatar, BDropdownItem, BLink, BNavbarNav, BNavItemDropdown,} from 'bootstrap-vue'
// import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { initialAbility } from '@/libs/acl/config'
import Locale from './Locale.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,

    // Navbar Components
    // DarkToggler,
    Locale,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      },
    },
  },
  computed: {
    getAccountId() {
      return this.$store.getters['auth/getAccountId'] ?? false
    }
  },
  methods: {
    logout() {
      this.$http.post('auth/logout')
          .then(response => {
            this.$store.dispatch('auth/logout')
            this.$ability.update(initialAbility)
            this.$router.push({name: 'login'})
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: this.$i18n.t('Success'),
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: this.$i18n.t('messages.success_logout'),
                    },
                  })
                })
          })
    },
    returnBackToAdminAccount(){
      this.$http.get(`/return-account/${this.getAccountId}`).then(response => {
        // logout
        this.$store.dispatch('auth/logout')
        this.$ability.update(initialAbility)
        // login
        const token = response.data.data.access_token
        const {user} = response.data.data
        this.$ability.update(user.ability)
        this.$store.dispatch('auth/login', {token, user})
        this.$router.replace('/')
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$i18n.t('Success'),
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })
            })
      })
    }
  },
}
</script>
