export default [
    {
        title: 'Home',
        route: 'home',
        icon: 'HomeIcon',
        resource: 'dashboard',
        action: 'read',
    },
    {
        title: 'Roles',
        route: 'roles',
        icon: 'SettingsIcon',
        resource: 'roles',
        action: 'read',
    },
    {
        title: 'Users',
        route: 'users',
        icon: 'UserIcon',
        resource: 'users',
        action: 'read',
    },
    {
        title: 'Settings',
        icon: 'SettingsIcon',
        children: [
            {
                title: 'settings.platform-mapping.name',
                route: 'platform-mapping',
                resource: 'settings',
                action: 'read',
            },
            {
                title: 'settings.platform-groups.name',
                route: 'platform-groups',
                resource: 'settings',
                action: 'read',
            },
        ]
    }
]
